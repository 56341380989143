import { get } from 'lodash'
import AppTitle from '@theme/components/atom/AppTitle'
import GoogleMapLink from '@theme/components/atom/GoogleMapLink'
import ImageUrlMixin from '~/mixins/ImageUrlMixin'
import LoadingMixin from '~/mixins/LoadingMixin'
import OpeningHours from '@theme/components/shop/OpeningHours'

export default {
  mixins: [ImageUrlMixin, LoadingMixin],
  components: {
    AppTitle,
    GoogleMapLink,
    OpeningHours,
  },
  data() {
    return {
      activeBranch: {},
      branches: [],
      fadeDescriptionIn: false,
    }
  },
  computed: {
    backgroundImage() {
      if (this.activeBranch && this.activeBranch.main_image && this.activeBranch.main_image.length > 0) {
        return this.getImageUrl(this.activeBranch.main_image[0].path)
      }
    },
  },
  async mounted() {
    this.startLoading()
    const branches = await this.$store.dispatch('branch/LOAD_BRANCHES', { page: 0, perPage: 5 })

    if (branches) {
      this.branches = branches.entities
      this.branches = this.branches.filter(branch => branch.storeBranch !== undefined)
      this.activeBranch = this.branches[0]

      // Preload background images
      this.branches.forEach(branch => {
        if (branch.main_image && branch.main_image.length > 0) {
          const img = new Image()
          img.src = this.getImageUrl(branch.main_image[0].path)
        }
      })
    }

    this.stopLoading()
  },
  methods: {
    getExtraOpeningHours(branch) {
      return get(branch, 'storeBranch.businessHours')
    },
    getImageUrl(path) {
      return this.imageUrl({ src: path, width: 2000 })
    },
    setActiveBranch(branch) {
      this.fadeDescriptionIn = true
      this.activeBranch = branch
      setTimeout(() => {
        this.fadeDescriptionIn = false
      }, 300)
    },
  },
}
